import { Icon, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { IconType } from "react-icons";


interface Props {
  icon: IconType;
  title: string;
  isSelected: boolean
  onClick: () => void;
}

const FilterButton: React.FC<Props> = ({ icon, title, isSelected, onClick }) => {
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const bgColor = useColorModeValue("gray.100", "gray.700");
  return (
    <VStack
      minWidth="120px"
      height="80px"
      borderColor={isSelected ? 'primary.500' : borderColor}
      opacity={isSelected ? 1 : 0.5}      
      borderWidth={2}
      borderRadius={20}
      align="start"
      justify="space-between"
      px={3}
      py={2}
      as="button"
      onClick={onClick}
      transition="all 0.1s"
      _hover={{
        bg: bgColor,
      }}
    >
      <Icon as={icon} boxSize={6} />
      <Text fontSize={{
        base: '14px',
        md: '16px',
      }}>
        {title}
      </Text>
      
    </VStack>
  );
};

export default FilterButton;