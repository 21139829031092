import { Message } from "./gen-ts/ai/assistants/v0/assistant_pb";
import { Timestamp } from "@bufbuild/protobuf";
import { Model } from "./gen-ts/ai/engine_pb";

export interface UserAccount {
  name: string;
  email: string;
}

export interface TenantInfo {
  logo: string;
  domains: string[];
}

export interface Config {
  auth0ClientId: string;
  auth0Audience: string;
  auth0Domain: string;
  backendUrl: string;
  grpcBackendUrl: string;
  whitelist: string[];
  blacklist: string[];
  adminEmails: string[];
  developerEmails: string[];
  tenants: {
    [ key: string ]: TenantInfo;
  };
}

export interface ChatInfo {
  threadId: string;
  createTime: Timestamp;
  assistantId: string;
  messages: Message[];
  streamingResponse?: StreamResultResponse;
  streamStartPending?: boolean;
}

export class StreamResultResponse {
  public runId: string;
  public threadId: string;
  private canceled: boolean = false;

  constructor(runId: string, threadId: string) {
    this.runId = runId;
    this.threadId = threadId;
  }

  public cancel() {
    this.canceled = true;
  }

  public isCanceled() {
    return this.canceled;
  }
}

export enum AssistantFilter {
  CERTIFIED = "Certified",
  FAVORITES = "Favorites",
  MY_ASSISTANTS = "My Assistants",
}

export interface AssistantFile {
  path: string;
  name: string;
  type: 'file' | 'folder';
  progress: number;
  status: 'uploading' | 'done' | 'error';
  error?: string;
  file?: File;
  children: AssistantFile[];
}

export interface ChannelConfig {
  config?: {
    assistantId: string;
    respondToAllConversations: boolean;
  }
  details?: {
    channelId: string;
    channelName: string;
    defaultAssistantId: string;
    slackbotId: string;
    slackbotName: string;
  },
  aliases: ChannelAlias[],
}

export interface ChannelAlias {
  name: string;
  assistantId: string;
}

export interface NewAssistantDetails {
  displayName: string;
  description: string;
  engine: string;
  model: string;
  instructions: string;
  modelSettings: {
    maxTokens: number;
    temperature: number;
    topP: number;
  };
  retrievalSettings: { topK: number; minRelevanceScore: number };
}

export interface Engine {
  name: string;
  models: {
    [ name: string ]: Model;
  };
}


export enum ModelFilter {
  VISION_CENTRIC = "Vision Centric",
  MULTI_MODAL = "Multi Modal",
  CODE_CENTRIC = "Code Centric",
  RECOMMENDED = "Recommended",
}

export interface ChatFile {
  file: File;
  image: string;
  status: 'uploading' | 'done' | 'error';
  progress: number;
  abortController: AbortController;
  error?: string;
  ref?: string;
}

export interface ChatMessageFile {
  contentType: string;
  fileName: string;
  url: string;
  ref: string;
  fetchTime: number;
}