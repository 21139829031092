import { AssistantFile } from "../../../types";

const unsupportedExtensions = [
  '.exe',
  '.exec',
  '.dll',
  '.reg',
  '.vbs',
  '.msi',
  '.msg',
  '.zip',
  '.rar',
  '.tar',
  '.tgz',
  '.tar.gz',
  '.vsd',
];

const unsupportedFolders = [ '.git', '.vsd', 'node_modules' ];
const unsupportedFilenames = [ '.DS_Store' ];

const isSupported = (file: AssistantFile): boolean => {

  if (file.type === 'folder') {
    return !unsupportedFolders.includes(file.name);
  }
  else {
    for (const ext of unsupportedExtensions) {
      if (file.name.endsWith(ext)) {
        return false;
      }
    }

    for (const filename of unsupportedFilenames) {
      if (file.name === filename) {
        return false;
      }
    }

    for (const dir of unsupportedFolders) {
      if (file.path.includes(dir)) {
        return false;
      }
    }
  }

  return true;
}

const getFileInstance = async (entry: FileSystemEntry): Promise<File> => {
  return new Promise((resolve, reject) => {
    const fileEntry = entry as FileSystemFileEntry;

    fileEntry.file((file) => {
      resolve(file);
    });
  });
}


const readDirEntries = async (dirEntry: FileSystemDirectoryEntry): Promise<FileSystemEntry[]> => {
  const reader = dirEntry.createReader();

  const children: FileSystemEntry[] = [];

  return new Promise((resolve) => {
    const readEntries = () => {
      reader.readEntries(async (entries) => {
        if (entries.length === 0) {
          resolve(children);
        } else {
          for (const ent of entries) {
            children.push(ent);
          }
          readEntries(); // Continue reading entries if there are more.
        }
      });
    };
    readEntries();
  });
}


export const traverseFileTree = async (entry: FileSystemEntry, currentPath: string, startingPath: string): Promise<AssistantFile> => {
  let path = currentPath;
  if (!path.endsWith('/')) {
    path += '/';
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  let file: AssistantFile | null = null;

  if (entry.isFile) {
    const fileEntry = entry as FileSystemFileEntry;
    const fileInstance = await getFileInstance(fileEntry);
    file = {
      type: 'file',
      path: (path + fileInstance.name).replace(/\/+/g, '/'),
      name: fileInstance.name,
      file: fileInstance,
      progress: 0,
      status: 'uploading',
      children: [],
    };
    if (!isSupported(file)) {
      file.status = 'error';
      file.error = 'Unsupported file type';
    }
  } else if (entry.isDirectory) {
    const dirEntry = entry as FileSystemDirectoryEntry;
    const dirPath = (startingPath + dirEntry.fullPath).replace(/\/+/g, '/');
    file = {
      type: 'folder',
      path: dirPath,
      name: dirEntry.name,
      progress: 0,
      status: 'uploading',
      children: [],
    }
    if (!isSupported(file)) {
      file.error = 'Unsupported folder';
      file.status = 'error';
    }
    else {
      const entries = await readDirEntries(dirEntry);
      for (const ent of entries) {
        const item = await traverseFileTree(ent, dirPath, startingPath);
        file!.children.push(item);
      }
    }
  }

  return file!;
}


export const findFolderByPath = (root: AssistantFile, targetPath: string): AssistantFile | null => {
  // Split the target path into parts
  const pathParts = targetPath.split('/').filter(Boolean); // remove empty strings from the split
  let currentFolder: AssistantFile | null = root;

  // Traverse through the folder structure
  for (const part of pathParts) {
    // If currentFolder has children, we search for the next folder part
    if (currentFolder && currentFolder.type === 'folder') {
      const nextFolder = currentFolder.children.find(
        (child) => child.type === 'folder' && child.name === part
      ) as AssistantFile | undefined;
      
      // If folder not found, return null
      if (!nextFolder) {
        return null;
      }
      currentFolder = nextFolder;
    } else {
      return null; // If it's not a folder at any point, return null
    }
  }

  return currentFolder?.type === 'folder' ? currentFolder : null;
};


export const moveFile = (file: AssistantFile, targetFolder: AssistantFile, root: AssistantFile): void => {
  
}