import { Box, Button, HStack, useColorModeValue, VStack } from "@chakra-ui/react";
import React from "react";

import EmptyDropZone from "./EmptyDropZone";
import { ROOT_FOLDER, useFileManager } from "./FileManagerProvider";
import CurrentFolder from "./CurrentFolder";
import RetrievalSettingsDialog from "../RetrievalSettingsDialog";
import BreadCrumbs from "./BreadCrumbs";





const FileManager: React.FC = () => {
  const [ retreivalSettingsOpen, setRetrievalSettingsOpen ] = React.useState(false);

  const fileManager = useFileManager();


  const bgColor = useColorModeValue("gray.50", "gray.700");
  const dividerColor = useColorModeValue("gray.200", "gray.700");

  if (fileManager.currentFolder === null) {
    return (
      <Box>
        Invalid path
      </Box>
    );
  }

  if (fileManager.currentPath === ROOT_FOLDER.path && fileManager.currentFolder.children.length === 0) {
    return (
      <EmptyDropZone path={ROOT_FOLDER.path} />
    );
  }


  return (
    <>
      <VStack
        width="100%"
        align="start"
      >
        <HStack width="100%" overflow="scroll">
          <Button
            colorScheme={
              !false ? 'primary' : 'gray'
            }
            color="black"
            onClick={fileManager.openFilePicker}
          >
            {
              'Upload files'
              // isDragActive ? 'Drop the files' : 'Upload files'
            }
          </Button>
          <Box flexGrow={1} height="1px" bgColor={dividerColor} />
          <Button variant="outline" onClick={() => setRetrievalSettingsOpen(true)}>
            Retrieval Settings
          </Button>
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Export file list
          </Button>
        </HStack>
        
        <BreadCrumbs />

        <Box bgColor={bgColor} borderRadius="5" width="100%" overflow="hidden">
          <CurrentFolder items={fileManager.currentFolder.children} />
        </Box>
      </VStack>
      <RetrievalSettingsDialog
        isOpen={retreivalSettingsOpen}
        onClose={() => setRetrievalSettingsOpen(false)}
        onUpdate={(data) => { }}
        retrievalSettings={fileManager.assistant.retrievalSettings!}
      />
    </>

  );
};

export default FileManager;