import React, { RefObject, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Spinner, useColorModeValue, } from "@chakra-ui/react";
import LeftPanel from "./LeftPanel";
import AppBar from "./AppBar";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserState } from "../../state/user";
import { AssistantDialogProvider, AssistantsDialog } from "../AssistantsDialog";
import DialogProvider, { DialogConsumer } from "../DialogProvider";


interface AppFrameProps {
  children?: React.ReactNode;
}

interface IAppFrameContext {
  titleRef: RefObject<HTMLElement | null> | undefined;
  bottomBarRef?: RefObject<HTMLElement | null> | undefined;
}

export const AppFrameContext = React.createContext<IAppFrameContext>({
  titleRef: undefined,
});


export const AppFrame: React.FC<AppFrameProps> = ({ children }) => {

  const [ isDrawerOpen, setIsDrawerOpen ] = useState(false)
  const titleRef = useRef<HTMLElement | null>(null);
  const bottomBarRef = useRef<HTMLElement | null>(null);

  var userState = useUserState();

  const { isLoading, isAuthenticated, loginWithRedirect, user, getIdTokenClaims } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      if (!isLoading && !isAuthenticated) {
        loginWithRedirect({
          appState: {
            returnTo: window.location.pathname + window.location.search,
          },
        });
      } else if (!isLoading && isAuthenticated) {
        const idTokenClaims = await getIdTokenClaims();
        const newToken = idTokenClaims?.__raw || null;
        // console.log(user);

        userState.setAuthData({
          user: {
            name: user?.name ?? '',
            email: user?.email ?? '',
          },
          authToken: newToken as string,
        });

        const redirectPath = localStorage.getItem("postLoginRedirect");
        if (redirectPath) {
          localStorage.removeItem("postLoginRedirect");
          navigate(redirectPath);
        }

        userState.loadPreferences();
      }
    }
    validateToken();

    // eslint-disable-next-line
  }, [ isLoading, isAuthenticated, loginWithRedirect, navigate, user ]);

  const closeDrawer = () => {
    setIsDrawerOpen(false)
  }
  const openDrawer = () => {
    setIsDrawerOpen(true)
  }


  var frameBgColor = useColorModeValue('gray.50', 'gray.900');
  var contentBgColor = useColorModeValue('#FFFFFF', 'gray.800');



  if (isLoading || !userState.user) {
    return (
      <Flex
        justify="center"
        align="center"
        width="100vw"
        height="100vh"
      >
        <Spinner
          boxSize={20}
          color="primary.500"
          speed='0.65s'
          emptyColor='gray.200'
          thickness="4px" />
      </Flex>
    );
  }

  return (
    <AppFrameContext.Provider value={{ titleRef, bottomBarRef }}>
      <AssistantDialogProvider>
        <DialogProvider>
          <Flex
            direction="row"
            width="100vw"
            height="100vh"
            maxWidth="100vw"
            maxHeight="100vh"
            bgColor={{
              base: contentBgColor,
              lg: frameBgColor,
            }}
          >
            <LeftPanel isInDrawer={false} />
            <Drawer
              isOpen={isDrawerOpen}
              placement='left'
              onClose={closeDrawer}
              size="xs"
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody p={0}>
                  <LeftPanel isInDrawer={true} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
            <Flex
              height='100%'
              width="100%"
              pt={{
                base: 0,
                lg: 4,
              }}
              pb={{
                base: 0,
                lg: 4,
              }}
              pr={{
                base: 0,
                lg: 4,
              }}
            >
              <Flex
                direction="column"
                bgColor={contentBgColor}
                borderRadius={10}
                boxShadow="sm"
                height='100%'
                width="100%"
                overflow="hidden"
              >
                <Box
                  zIndex={999}
                  display={{
                    base: 'block',
                    lg: 'none',
                  }}>
                  <AppBar onMenuOpen={openDrawer}>
                    <Flex width="100%" direction="row" ref={titleRef as any}>
                    </Flex>
                  </AppBar>
                </Box>

                <Flex
                  id="app-frame-page-container"
                  justify="center"
                  overflow="hidden"
                  width="100%"
                  height="100%"
                  position="relative"
                >
                  <Outlet />
                  <AssistantsDialog />
                  <DialogConsumer />
                  <Box position="absolute" bottom="0px" right="0px" left="0px" ref={bottomBarRef as any}>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </DialogProvider>
      </AssistantDialogProvider>
    </AppFrameContext.Provider>
  );
}

