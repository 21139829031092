// @generated by protoc-gen-es v1.10.0 with parameter "target=ts,import_extension=none"
// @generated from file ai/message.proto (package ai, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message as Message$1, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum ai.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  ROLE_UNSPECIFIED = 0,

  /**
   * A user-specified message
   *
   * @generated from enum value: USER = 1;
   */
  USER = 1,

  /**
   * A response from the chat engine
   *
   * @generated from enum value: ASSISTANT = 2;
   */
  ASSISTANT = 2,

  /**
   * A system prompt
   *
   * @generated from enum value: SYSTEM = 3;
   */
  SYSTEM = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Role)
proto3.util.setEnumType(Role, "ai.Role", [
  { no: 0, name: "ROLE_UNSPECIFIED" },
  { no: 1, name: "USER" },
  { no: 2, name: "ASSISTANT" },
  { no: 3, name: "SYSTEM" },
]);

/**
 * A message that is part of a chat interaction with a ChatEngine
 *
 * @generated from message ai.Message
 */
export class Message extends Message$1<Message> {
  /**
   * The role of the message party
   *
   * @generated from field: ai.Role role = 1;
   */
  role = Role.ROLE_UNSPECIFIED;

  /**
   * The text of the message.
   * This is short-hand for including single Content element with a text value.
   *
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * The content of the message
   *
   * @generated from field: repeated ai.Message.Content content = 6;
   */
  content: Message_Content[] = [];

  constructor(data?: PartialMessage<Message>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Message";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role", kind: "enum", T: proto3.getEnumType(Role) },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "content", kind: "message", T: Message_Content, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message {
    return new Message().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message {
    return new Message().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message {
    return new Message().fromJsonString(jsonString, options);
  }

  static equals(a: Message | PlainMessage<Message> | undefined, b: Message | PlainMessage<Message> | undefined): boolean {
    return proto3.util.equals(Message, a, b);
  }
}

/**
 * A piece of content within a Message
 * Content may be text, an image, or tool interactions.
 *
 * @generated from message ai.Message.Content
 */
export class Message_Content extends Message$1<Message_Content> {
  /**
   * The value of the content
   *
   * @generated from oneof ai.Message.Content.content
   */
  content: {
    /**
     * Text content
     *
     * @generated from field: string text = 1;
     */
    value: string;
    case: "text";
  } | {
    /**
     * An image, either a remote URL or base64 encoded image data
     *
     * @generated from field: ai.Message.Content.ImageSource image = 2;
     */
    value: Message_Content_ImageSource;
    case: "image";
  } | {
    /**
     * A tool being called by the model.
     * This should only be populated in ASSISTANT messages.
     *
     * @generated from field: ai.Message.Content.ToolCall tool_call = 3;
     */
    value: Message_Content_ToolCall;
    case: "toolCall";
  } | {
    /**
     * A response to a tool being called.
     * This should only be populated in USER messages.
     *
     * @generated from field: ai.Message.Content.ToolResponse tool_response = 4;
     */
    value: Message_Content_ToolResponse;
    case: "toolResponse";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Message_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Message.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "content" },
    { no: 2, name: "image", kind: "message", T: Message_Content_ImageSource, oneof: "content" },
    { no: 3, name: "tool_call", kind: "message", T: Message_Content_ToolCall, oneof: "content" },
    { no: 4, name: "tool_response", kind: "message", T: Message_Content_ToolResponse, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message_Content {
    return new Message_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message_Content {
    return new Message_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message_Content {
    return new Message_Content().fromJsonString(jsonString, options);
  }

  static equals(a: Message_Content | PlainMessage<Message_Content> | undefined, b: Message_Content | PlainMessage<Message_Content> | undefined): boolean {
    return proto3.util.equals(Message_Content, a, b);
  }
}

/**
 * The source of an image.
 * A source can either be the remote URL of an image or the actual contents of the image
 * Note: Not all engine implementations support image URLs
 *
 * @generated from message ai.Message.Content.ImageSource
 */
export class Message_Content_ImageSource extends Message$1<Message_Content_ImageSource> {
  /**
   * Images may be either a remote URL to an image or the contents of the image itself.
   *
   * @generated from oneof ai.Message.Content.ImageSource.source
   */
  source: {
    /**
     * Image URL
     *
     * @generated from field: string url = 1;
     */
    value: string;
    case: "url";
  } | {
    /**
     * Image contents
     *
     * @generated from field: ai.Message.Content.ImageSource.ImageData image_data = 2;
     */
    value: Message_Content_ImageSource_ImageData;
    case: "imageData";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Message_Content_ImageSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Message.Content.ImageSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "source" },
    { no: 2, name: "image_data", kind: "message", T: Message_Content_ImageSource_ImageData, oneof: "source" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message_Content_ImageSource {
    return new Message_Content_ImageSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message_Content_ImageSource {
    return new Message_Content_ImageSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message_Content_ImageSource {
    return new Message_Content_ImageSource().fromJsonString(jsonString, options);
  }

  static equals(a: Message_Content_ImageSource | PlainMessage<Message_Content_ImageSource> | undefined, b: Message_Content_ImageSource | PlainMessage<Message_Content_ImageSource> | undefined): boolean {
    return proto3.util.equals(Message_Content_ImageSource, a, b);
  }
}

/**
 * Contents of an attached image
 *
 * @generated from message ai.Message.Content.ImageSource.ImageData
 */
export class Message_Content_ImageSource_ImageData extends Message$1<Message_Content_ImageSource_ImageData> {
  /**
   * The type of image
   *
   * @generated from field: string mime_type = 1;
   */
  mimeType = "";

  /**
   * The contents of the image
   *
   * @generated from field: bytes data = 2;
   */
  data = new Uint8Array(0);

  constructor(data?: PartialMessage<Message_Content_ImageSource_ImageData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Message.Content.ImageSource.ImageData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message_Content_ImageSource_ImageData {
    return new Message_Content_ImageSource_ImageData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message_Content_ImageSource_ImageData {
    return new Message_Content_ImageSource_ImageData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message_Content_ImageSource_ImageData {
    return new Message_Content_ImageSource_ImageData().fromJsonString(jsonString, options);
  }

  static equals(a: Message_Content_ImageSource_ImageData | PlainMessage<Message_Content_ImageSource_ImageData> | undefined, b: Message_Content_ImageSource_ImageData | PlainMessage<Message_Content_ImageSource_ImageData> | undefined): boolean {
    return proto3.util.equals(Message_Content_ImageSource_ImageData, a, b);
  }
}

/**
 * A tool invocation from the model
 *
 * @generated from message ai.Message.Content.ToolCall
 */
export class Message_Content_ToolCall extends Message$1<Message_Content_ToolCall> {
  /**
   * ID of the tool call
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Name of the function
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * Arguments of the function call, formatted as JSON
   *
   * @generated from field: string arguments = 3;
   */
  arguments = "";

  constructor(data?: PartialMessage<Message_Content_ToolCall>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Message.Content.ToolCall";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "arguments", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message_Content_ToolCall {
    return new Message_Content_ToolCall().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message_Content_ToolCall {
    return new Message_Content_ToolCall().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message_Content_ToolCall {
    return new Message_Content_ToolCall().fromJsonString(jsonString, options);
  }

  static equals(a: Message_Content_ToolCall | PlainMessage<Message_Content_ToolCall> | undefined, b: Message_Content_ToolCall | PlainMessage<Message_Content_ToolCall> | undefined): boolean {
    return proto3.util.equals(Message_Content_ToolCall, a, b);
  }
}

/**
 * A response to a ToolCall
 *
 * @generated from message ai.Message.Content.ToolResponse
 */
export class Message_Content_ToolResponse extends Message$1<Message_Content_ToolResponse> {
  /**
   * The ID of the ToolCall that this is a response to.
   *
   * @generated from field: string tool_call_id = 1;
   */
  toolCallId = "";

  /**
   * Indicates that the tool call failed
   *
   * @generated from field: bool error = 2;
   */
  error = false;

  /**
   * The content of the tool response
   * Currently only text is supported, but additional response types may be supported in the future.
   *
   * @generated from oneof ai.Message.Content.ToolResponse.content
   */
  content: {
    /**
     * @generated from field: string text = 3;
     */
    value: string;
    case: "text";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<Message_Content_ToolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "ai.Message.Content.ToolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tool_call_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "content" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Message_Content_ToolResponse {
    return new Message_Content_ToolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Message_Content_ToolResponse {
    return new Message_Content_ToolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Message_Content_ToolResponse {
    return new Message_Content_ToolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: Message_Content_ToolResponse | PlainMessage<Message_Content_ToolResponse> | undefined, b: Message_Content_ToolResponse | PlainMessage<Message_Content_ToolResponse> | undefined): boolean {
    return proto3.util.equals(Message_Content_ToolResponse, a, b);
  }
}

