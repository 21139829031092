import React, { useEffect, useRef, useState } from "react";
import { useChats } from "../../state/chats";
import { useAssistants } from "../../state/assistants";
import {
  Flex,
  HStack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import ChatInput, { InputHeightProvider } from "./ChatInput";
import ChatMessages from "./ChatMessages";
import { useNavigate } from "react-router-dom";
import AppPage from "../../components/AppFrame/AppPage";
import AppPageHeader from "../AppFrame/AppPageHeader";
import ExistingChatAssistantButton from "./ExistingChatAssistantButton";
import EmptyChat from "./EmptyChat";
import FileUpload from "./FileUpload";
import ScrollDownButton from "./ScrollDownButton";
import { ChatFile } from "../../types";


type ChatUIProps = {
  threadId?: string;
}

const ChatUI: React.FC<ChatUIProps> = ({ threadId }) => {

  const firstRenderRef = useRef(true);
  const navigate = useNavigate();

  const chatsState = useChats();
  const assistantsState = useAssistants();

  const chat = threadId ? chatsState.chats[ threadId! ] : null;

  const assistantId = chat?.assistantId ?? assistantsState.selectedAssistant;
  const assistant = assistantsState.assistants[ assistantId ];

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const [ isUserScrolled, setIsUserScrolled ] = useState(false);

  const topbarBorderColor = useColorModeValue('gray.200', 'gray.700');


  useEffect(() => {
    if (threadId) {
      chatsState.loadChatByThreadId(threadId);
    }
    // eslint-disable-next-line
  }, [ threadId ]);

  useEffect(() => {
    assistantsState.loadById(assistantId, true);
    //eslint-disable-next-line
  }, [ assistantId ]);

  useEffect(() => {
    if (chat?.messages.length === 0) {
      return;
    }

    scrollToBottom();
    //eslint-disable-next-line
  }, [ chat ]);

  const stopStreaming = () => {
    chat?.streamingResponse?.cancel();
  }

  const onUserScrollUpdate = (scrolled: boolean) => {
    if (scrolled !== isUserScrolled) {
      setIsUserScrolled(scrolled);
    }
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current && !isUserScrolled) {
      const scrollBehavior = firstRenderRef?.current ? "auto" : "smooth";
      messagesEndRef.current.scrollIntoView({ behavior: scrollBehavior });
      firstRenderRef.current = false;
    }
  };


  const onSendMessage = async (message: string, files: ChatFile[]) => {
    console.log('onSend', message);
    if (!chat) {
      const threadId = await chatsState.newChat({ assistantId, message, files });
      if (threadId) {
        chatsState.startStreaming({ threadId });
        navigate(`/chat/${threadId}`);
      }
    }
    else {
      await chatsState.newMessage({ threadId: chat.threadId, message, assistantId, files });
      chatsState.startStreaming({ threadId: chat.threadId });
    }
  }

  if (!chat && !chatsState.loadingById) {
    return (
      <FileUpload>
        <InputHeightProvider>
          <EmptyChat assistant={assistant} assistantId={assistantId} onSendMessage={onSendMessage} />
        </InputHeightProvider>
      </FileUpload>
    );
  }



  return (
    <AppPage
      px={4}
      py={2}
    >
      <InputHeightProvider>
        <FileUpload>
          <AppPageHeader
            onlyMobile
            actions={(
              <HStack
                width="calc(100vw - 66px)"
                justify="space-between"
                spacing={0}
              >
                <ExistingChatAssistantButton
                  isLoading={assistantsState.loadingById[ assistantId ] || chatsState.loadingById}
                  assistant={assistant}
                  isSmall
                />
              </HStack>
            )} />

          <VStack
            id="chat-ui-vstack"
            width="100%"
            height="100%"
            flexGrow={1}
            align="center"
            justify="stretch"
          >

            <HStack
              borderBottomWidth="1px"
              borderBottomColor={topbarBorderColor}
              width="100%"
              pb={1}
              justify="space-between"
              height={53}
              display={{ base: 'none', lg: 'flex' }}
            >
              <ExistingChatAssistantButton
                isLoading={assistantsState.loadingById[ assistantId ] || chatsState.loadingById}
                assistant={assistant}
                isSmall={false}
              />
            </HStack>
            <ChatMessages chat={chat} ref={messagesEndRef} onUserScrollUpdate={onUserScrollUpdate} />

            <Flex width="100%" justify="center">
              <ChatInput
                onSend={onSendMessage}
                onStop={stopStreaming}
                status={(chat?.streamingResponse) ? 'sending' : 'idle'}
                loading={assistantsState.loadingById[ assistantId ] || chatsState.loadingById}
                streamStartPending={chat?.streamStartPending}
                newChatPending={chatsState.newChatPending}
              />
            </Flex>
          </VStack>
          <ScrollDownButton
            visible={isUserScrolled}
            scrollDownRef={messagesEndRef}
          />

        </FileUpload>
      </InputHeightProvider>
    </AppPage>
  )
};

export default ChatUI;