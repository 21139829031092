import { Box, Progress } from "@chakra-ui/react";
import React from "react";
import { MobileAppBarHeight } from "./AppBar";


interface AppPageProps {
  p?: number;
  px?: number;
  py?: number;
  maxWidth?: number;
  isLoading?: boolean;
}

const AppPage: React.FC<React.PropsWithChildren<AppPageProps>> = ({ children, p, px, py, maxWidth, isLoading }) => {
  return (
    <>
      {
        isLoading && (
          <Box
            position="absolute"
            top="0px"
            left="0px"
            width="100%"
            pointerEvents="none"
          >
            <Progress size='sm' isIndeterminate colorScheme="primary"/>
          </Box>
        )
      }
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        overflowY="scroll"
        height={{
          base: `calc(100vh - ${MobileAppBarHeight}px)`,
          lg: '100%',
        }}
        position="relative"
      >

        <Box
          p={p}
          px={px}
          py={py}
          height="100%"
          width="100%"
          maxWidth={maxWidth}
          // bgColor="blue"
          id="app-page-vstack"
          // align="start"
          position="relative"
        >
          {children}
          <Box bgColor="transparent" width="100px" height={p}></Box>
        </Box>
      </Box>
    </>
  );
};

export default AppPage;