class LocalStorage {
  
  public static defaultAssistantId = 'default';

  public static getSelectedAssistantId(): string {
    return window.localStorage.getItem('selectedAssistantId') || LocalStorage.defaultAssistantId;
  }

  public static setSelectedAssistantId(id: string): void {
    return window.localStorage.setItem('selectedAssistantId', id);
  }
}

export default LocalStorage;