import React, { useEffect, useRef, useState } from "react";
import AppPage from "../components/AppFrame/AppPage";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import AppPageHeader from "../components/AppFrame/AppPageHeader";
import { Button, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { MdAddCircle, MdDelete, MdMoreHoriz } from "react-icons/md";
import ManageAssistantForm from "../components/ManageAssistantForm/ManageAssistantForm";
import { useAssistants } from "../state/assistants";
import { useDialog } from "../components/DialogProvider";



const EditAssistantPage: React.FC = () => {
  const { assistantId } = useParams<{ assistantId: string }>();
  const assistants = useAssistants();
  const navigate = useNavigate();
  const dialog = useDialog();

  const originalAssistant = assistants.assistants[ assistantId ?? '' ] ?? null;

  const [ assistant, setAssistant ] = useState(originalAssistant?.clone());

  const isLoading = assistants.loadingById[ assistantId ?? '' ];



  const mountTime = useRef(Date.now());


  const isNew = assistantId ? assistants.newAssistants.includes(assistantId) : false;

  useEffect(() => {
    if (assistantId) {
      assistants.loadById(assistantId);
    }
    // eslint-disable-next-line
  }, [ assistantId ]);

  useBeforeUnload(
    React.useCallback(() => {
      console.log('before unload');
    }, [])
  );

  useEffect(() => {

    mountTime.current = Date.now();

    return () => {
      if (Date.now() - mountTime.current < 1000) {
        return;
      }

      if (isNew && assistantId) {
        assistants.deleteNewAssistant(assistantId);
      }
    }

    // eslint-disable-next-line
  }, [ isNew ]);


  useEffect(() => {
    if (originalAssistant) {
      setAssistant(originalAssistant.clone());
    }

  }, [ originalAssistant, isLoading ]);

  // useEffect(() => {
  //   setOriginalAssistant(assistants.assistants[ assistantId ?? '' ] ?? null);
  // }, [ assistants.assistants, assistantId ]);

  const onCancel = () => {
    if (isNew && assistantId) {
      navigate(-1);
    }
    else {
      navigate('/assistants', { replace: true });
    }
  }

  const onDelete = () => {
    if (assistantId) {
      dialog.confirm(
        {
          title: 'Delete Assistant',
          subtitle: 'Are you sure you want to delete this assistant?',
          onConfirm: () => {
            assistants.deleteAssistant(assistantId);
            navigate('/assistants', { replace: true });
          }
        }
      );

    }
  }

  const onSave = () => {
    assistants.setAssistants([ assistant ]);
  }

  const modified = !assistant?.equals(originalAssistant) || isNew;

  const isOwner = assistants.haveOwnerPermission(assistantId ?? '');
  const isUploader = assistants.haveUploadPermission(assistantId ?? '');

  return (
    <AppPage isLoading={assistants.loadingById[ assistantId ?? '' ]} maxWidth={1200} p={4}>
      <AppPageHeader
        title={isNew ? 'Create assistant' : 'Edit assistant'}
        actions={(
          <HStack>
            {
              !isNew && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    icon={<MdMoreHoriz />}
                    variant='ghost'
                    size="sm"
                  />
                  <MenuList>
                    <MenuItem icon={<MdAddCircle />}>
                      Clone Assistant
                    </MenuItem>
                    <MenuItem icon={<MdDelete />} onClick={onDelete} isDisabled={!isOwner}>
                      Delete Assistant
                    </MenuItem>
                  </MenuList>
                </Menu>
              )
            }
            <Button onClick={onCancel} size="sm">
              {
                modified ? 'Cancel' : 'Back'
              }
            </Button>
            <Button
              isDisabled={!modified || (!isOwner && !isUploader)}
              colorScheme="primary"
              color="black"
              onClick={onSave}
              size="sm"
            >
              {
                isNew ? 'Save' : 'Save Changes'
              }
            </Button>
          </HStack>
        )}
      />

      {
        assistant && (
          <ManageAssistantForm
            key={assistantId}
            data={assistant}
            isUploader={isUploader}
            isOwner={isOwner}
            onUpdate={(assistant) => {
              setAssistant(assistant);
            }}
          />
        )
      }
    </AppPage>
  );
};

export default EditAssistantPage;