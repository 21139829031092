import { create } from "zustand"
import { getApiClient } from "../api";
import {
  Assistant,
  GetAssistantRequest,
  ListAssistantsRequest,
} from "../gen-ts/ai/assistants/v0/assistant_pb";
import { AssistantFilter, NewAssistantDetails } from "../types";
import { Index } from "flexsearch-ts";
import LocalStorage from "../services/localStorage";
import { useUserState } from "./user";



interface AssistantsState {
  assistants: {
    [ assistantId: string ]: Assistant;
  },
  newAssistants: string[],
  loadingList: boolean,
  listLoaded: boolean,
  loadingById: {
    [ assistantId: string ]: boolean
  },
  searchIndex: Index,
  selectedAssistant: string;
  updateSearchIndex: () => void,
  setSelectedAssistantId: (assistantId: string) => void,
  loadList: (args: { pageSize: number }) => void,
  loadById: (assistantId: string, ifErrorResetToDefault?: boolean) => void,
  setAssistants: (assistants: Assistant[]) => void,
  filters: AssistantFilter[],
  toggleFilter: (filter: AssistantFilter) => void,
  createNewAssistant: () => Promise<string | null>,
  deleteAssistant: (assistantId: string) => void,
  deleteNewAssistant: (assistantId: string) => void,
  haveUploadPermission: (assistant: string) => boolean,
  haveOwnerPermission: (assistantId: string) => boolean,
}

export const useAssistants = create<AssistantsState>((set, get) => ({
  assistants: {},
  newAssistants: [],
  loadingList: false,
  loadingById: {},
  listLoaded: false,
  filters: [],
  favorites: [],
  selectedAssistant: LocalStorage.getSelectedAssistantId(),
  setSelectedAssistantId: (assistantId: string) => {
    set({ selectedAssistant: assistantId });
    LocalStorage.setSelectedAssistantId(assistantId);
  },
  searchIndex: new Index({
    tokenize: 'full',
  }),
  updateSearchIndex: () => {
    for (const id in get().assistants) {
      const assistant = get().assistants[ id ];
      const content = `${assistant.displayName}.\n${assistant.description}`;
      get().searchIndex.add(id, content);
    }
  },
  loadList: async ({ pageSize = 100 }) => {
    if (get().loadingList || get().listLoaded) {
      return;
    }

    set({ loadingList: true });

    try {
      const { client, headers } = getApiClient();

      let hasMoreData = true;
      let pageToken: string | undefined = undefined;

      while (hasMoreData) {
        const listAssistantsReq: ListAssistantsRequest = new ListAssistantsRequest({ pageSize, pageToken });
        const listAssistantsResp = await client.listAssistants(listAssistantsReq, { headers });
        pageToken = listAssistantsResp.nextPageToken;
        if (!pageToken) {
          hasMoreData = false;
        }

        set((state) => {
          const assistants = { ...state.assistants };
          listAssistantsResp.assistants.forEach((assistant) => {
            assistants[ assistant.id ] = assistant;
          });
          return { assistants, nextPageToken: listAssistantsResp.nextPageToken };
        });
      }


      set({ listLoaded: true });




      get().updateSearchIndex();
    } catch (e) {
      console.error(e);
    } finally {
      set({ loadingList: false });
    }
  },
  loadById: async (assistantId: string, ifErrorResetToDefault: boolean = false) => {
    if (get().loadingById[ assistantId ]) {
      return;
    }

    if (get().assistants[ assistantId ] && get().assistants[ assistantId ].instructions.trim() !== '') {
      return;
    }

    set({
      loadingById: {
        ...get().loadingById,
        [ assistantId ]: true,
      }
    });

    try {
      const assistant = await loadAssistantById(assistantId, ifErrorResetToDefault);
      console.log('assistant', assistant);
      set((state) => {
        const assistants = { ...state.assistants };

        assistants[ assistantId ] = assistant.clone();
        return { assistants };
      });

      get().updateSearchIndex();
    } catch (e) {
      console.error(e);
    } finally {
      set({
        loadingById: {
          ...get().loadingById,
          [ assistantId ]: false,
        }
      });
    }
  },
  setAssistants: (assistants: Assistant[]) => {
    const map: { [ assistantId: string ]: Assistant } = {};
    assistants.forEach((assistant) => {
      map[ assistant.id ] = assistant;
    });


    const ids = assistants.map((a) => a.id);

    set({
      assistants: {
        ...get().assistants,
        ...map,
      },
      newAssistants: get().newAssistants.filter((id) => !ids.includes(id)),
    });
    get().updateSearchIndex();
  },
  toggleFilter: (filter: AssistantFilter) => {
    set((state) => {
      const filters = state.filters.includes(filter)
        ? state.filters.filter((f) => f !== filter)
        : [ ...state.filters, filter ];
      return { filters };
    });
  },
  createNewAssistant: async () => {
    try {
      const userName = useUserState.getState().user?.name;
      if (!userName) {
        return null;
      }

      const defaultAssistant = get().assistants[ 'default' ] || await loadAssistantById('default', false);

      const date = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });

      const newAssistantName = `${userName}'s Assistant (${date})`;

      const newAssistantDetails: NewAssistantDetails = {
        displayName: newAssistantName,
        description: defaultAssistant.description,
        engine: defaultAssistant.engine,
        model: defaultAssistant.model,
        instructions: defaultAssistant.instructions,
        modelSettings: {
          maxTokens: defaultAssistant.modelSettings!.maxTokens!,
          temperature: defaultAssistant.modelSettings!.temperature!,
          topP: defaultAssistant.retrievalSettings!.topK!,
        },
        retrievalSettings: {
          topK: defaultAssistant.retrievalSettings!.topK!,
          minRelevanceScore: defaultAssistant.retrievalSettings!.minRelevanceScore!,
        },
      };

      const { client, headers } = getApiClient();

      const newAssistant = await client.createAssistant(
        newAssistantDetails,
        { headers },
      );

      get().setAssistants([ newAssistant ]);
      set({
        newAssistants: [ ...get().newAssistants, newAssistant.id ],
      });

      return newAssistant.id;
    }
    catch (e) {
      console.error(e);
    }

    return null;
  },
  deleteAssistant: async (assistantId: string) => {
    try {
      const { client, headers } = getApiClient();

      const request = new GetAssistantRequest({
        id: assistantId,
      });

      set((state) => {
        const assistants = { ...state.assistants };
        delete assistants[ assistantId ];

        return {
          assistants,
          newAssistants: state.newAssistants.filter((id) => id !== assistantId),
        };
      });
      get().updateSearchIndex();

      await client.deleteAssistant(request, { headers });
    } catch (e) {
      console.error(e);
    }
  },
  deleteNewAssistant: (assistantId: string) => {
    if (!get().newAssistants.includes(assistantId)) {
      return;
    }

    get().deleteAssistant(assistantId);
  },
  haveUploadPermission: (assistantId: string) => {
    const assistant = get().assistants[ assistantId ];
    if (!assistant) {
      return false;
    }

    const domain = useUserState.getState().getDomain();
    const email = useUserState.getState().user?.email ?? '';

    return assistant.uploaders.includes(`domain:${domain}`) || assistant.uploaders.includes(`email:${email}`);
  },
  haveOwnerPermission: (assistantId: string) => {
    const assistant = get().assistants[ assistantId ];
    if (!assistant) {
      return false;
    }

    const domain = useUserState.getState().getDomain();
    const email = useUserState.getState().user?.email ?? '';

    return assistant.owners.includes(`domain:${domain}`) ||
      assistant.owners.includes(`email:${email}`) ||
      assistant.owner === email;
  },
}));



const loadAssistantById = async (assistantId: string, ifErrorResetToDefault: boolean): Promise<Assistant> => {
  try {
    const { client, headers } = getApiClient();

    const getAssistantReq = new GetAssistantRequest({
      id: assistantId,
    });
    const assistant = await client.getAssistant(getAssistantReq, { headers });
    return assistant;
  }
  catch (e) {
    console.error(e);
    if (ifErrorResetToDefault) {
      LocalStorage.setSelectedAssistantId(LocalStorage.defaultAssistantId);
    }

    return await loadAssistantById(LocalStorage.defaultAssistantId, false);
  }

}