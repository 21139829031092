import React from "react";
import FileManagerProvider from "./FileManager/FileManagerProvider";
import FileManager from "./FileManager/FileManager";
import { Assistant } from "../../gen-ts/ai/assistants/v0/assistant_pb";


interface Props {
  assistant: Assistant;
}

const TabAssistantFiles: React.FC<Props> = ({assistant}) => {
  return (
    <FileManagerProvider assistant={assistant} key={assistant.id}>
      <FileManager />
    </FileManagerProvider>
  );
};

export default TabAssistantFiles;