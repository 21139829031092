import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdDelete, MdKeyboardArrowDown } from "react-icons/md";
import { useAssistants } from "../../state/assistants";
import { useAssistantsDialog } from "../../components/AssistantsDialog";
import { Alias } from "../../gen-ts/slack/slack_config_pb";

interface Props {
  aliases: { [ key: string ]: Alias };
  onAddAlias: (name: string, alias: Alias) => void;
  onDeleteAlias: (name: string) => void;
}

const ChannelAliases: React.FC<Props> = ({ aliases, onAddAlias, onDeleteAlias }) => {
  const [ expanded, setExpanded ] = useState(false);
  const assistants = useAssistants();
  const assistantDialog = useAssistantsDialog();
  const [ aliasName, setAliasName ] = useState('');

  const [ aliasAssistantId, setAliasAssistantId ] = useState(assistants.selectedAssistant);



  useEffect(() => {
    assistants.loadById(
      assistants.selectedAssistant,
    );
    //eslint-disable-next-line
  }, [ aliasAssistantId ]);

  useEffect(() => {
    console.log('load alias assistants');

    for (const alias in (aliases ?? {})) {
      const assistantId = aliases[ alias ].assistantId;
      assistants.loadById(assistantId);
    }

    //eslint-disable-next-line
  }, [ aliases ]);


  const aliaseslist = Object.keys(aliases ?? {});
  aliaseslist.sort();

  const leftColumnWidth = {
    'base': '100%',
    'md': '350px',
  };

  const selectedAssistant = assistants.assistants[ aliasAssistantId ];

  const tableBgColor = useColorModeValue('white', 'gray.800');

  const onAdd = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onAddAlias(aliasName, new Alias({ assistantId: aliasAssistantId }));
    setAliasName('');
  }

  return (
    <>
      <form onSubmit={onAdd}>
        <VStack
          bgColor={useColorModeValue('gray.50', 'gray.700')}
          borderRadius={10}
          p={4}
          width="100%"
        >
          <HStack width="100%" justify="space-between">
            <Text fontWeight="600">Create Alias</Text>
            <Button
              size="sm"
              variant="ghost"
              rightIcon={
                <Icon
                  aria-label="expand"
                  transition='transform 0.2s'
                  transform={expanded ? 'rotate(180deg)' : ''}
                  as={
                    MdKeyboardArrowDown
                  }
                />
              }
              onClick={() => setExpanded(!expanded)}
            >
              How does it work
            </Button>
          </HStack>
          <Collapse in={expanded} animateOpacity startingHeight="1px">
            <HStack mt="1px" p={3} borderRadius={10} spacing={4} bgColor={useColorModeValue('gray.200', 'gray.800')}>
              <svg width="56" height="78" viewBox="0 0 56 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="78" rx="28" fill="#80FDE7" />
                <path d="M39 36H30L32 24L17 42H26L24 54L39 36Z" fill="#001A15" />
              </svg>
              <VStack align="start" spacing={0}>
                <Text fontWeight="600">Assistant Aliases</Text>
                <Text>
                  Easily switch between different assistants within the same chat channel—no need to juggle multiple channels. Create as many aliases for this channel as you’d like.
                </Text>
              </VStack>
            </HStack>
          </Collapse>
          <VStack
            width="100%"
            align="start"
            mt={2}
            bgColor={useColorModeValue('white', 'gray.700')}
            borderRadius={10}
            p={4}
          >
            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
              align={{
                base: "start",
                lg: "center"
              }}

              width="100%"
            >
              <Text fontWeight="600" width={leftColumnWidth}>Alias Name</Text>
              <Input
                type="text"
                width="100%"
                value={aliasName}
                onChange={(e) => setAliasName(e.target.value)}
              />
            </Stack>

            <Divider my={3} />

            <Stack
              direction={{
                base: 'column',
                lg: 'row',
              }}
              align={{
                base: "start",
                lg: "center"
              }}
              width="100%"
            >
              <Text fontWeight="600" width={leftColumnWidth}>{selectedAssistant?.displayName}</Text>
              <Box width="100%">
                <Button
                  variant="outline"
                  rightIcon={
                    <Icon
                      aria-label="change assistant"
                      as={
                        MdKeyboardArrowDown
                      }
                    />
                  }
                  onClick={() => {
                    assistantDialog.open({
                      onSelect: (assistantId) => {
                        console.log('set alias assistant id', assistantId);
                        setAliasAssistantId(assistantId);
                      }
                    });
                  }}
                >
                  Change Assistant
                </Button>
              </Box>
            </Stack>

            <HStack justify="end" width="100%" mt={5}>
              <Button
                type="submit"
                variant="outline"
              >
                Submit
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </form>

      <VStack
        width="100%"
        align="start"
        mt={2}
        bgColor={useColorModeValue('gray.100', 'gray.900')}
        borderRadius={10}
        p={4}
      >
        <Text fontWeight="600">All aliases for this channel</Text>

        {
          aliaseslist.length === 0 && (
            <Flex justify="center" align="center" width="100%">
              <Text>No aliases</Text>
            </Flex>
          )
        }


        {
          aliaseslist.length > 0 && (
            <TableContainer bgColor={tableBgColor} borderRadius={10} width="100%" mt={4}>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Alias</Th>
                    <Th>Assistant</Th>
                    <Th>Description</Th>
                    <Th width="0px"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    aliaseslist.map((aliasName) => {
                      const alias = aliases[ aliasName ];
                      if (!alias) {
                        return null;
                      }

                      const assistant = assistants.assistants[ alias.assistantId ];
                      return (
                        <Tr key={aliasName}>
                          <Td>{aliasName}</Td>
                          <Td>{assistant?.displayName}</Td>
                          <Td>{assistant?.description}</Td>
                          <Td>
                            <IconButton
                              icon={<MdDelete />}
                              aria-label="Delete"
                              variant="ghost"
                              onClick={() => {
                                onDeleteAlias(aliasName);
                              }}
                            />
                          </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </TableContainer>
          )
        }
      </VStack>
    </>
  );
};

export default ChannelAliases;