import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
  Wrap,
  useClipboard,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { ChatInfo } from "../../types";
import { Message, Role } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import { MdContentCopy, MdLanguage } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { useChats } from "../../state/chats";
import { ExternalLinkIcon } from "@chakra-ui/icons";


interface ChatMessagesProps {
  chat: ChatInfo | null;
  onUserScrollUpdate: (isUserScrolled: boolean) => void;
}

const ChatMessages = forwardRef<HTMLDivElement, ChatMessagesProps>(({ chat, onUserScrollUpdate }, ref) => {

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [ currMessageSources, setCurrMessageSources ] = useState<string | null>(null);

  const showSources = (runId: string) => {
    setCurrMessageSources(runId);
  }

  const hideSources = () => {
    setCurrMessageSources(null);
  }

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onUserScrollUpdate(false);
      } else {
        onUserScrollUpdate(true);
      }
    }
  };

  return (
    <>
      <VStack height="100%" width="100%" overflow="scroll" onScroll={handleScroll} ref={containerRef}>
        <VStack maxWidth="1200px" width="100%">
          {
            chat?.messages.map((message, index) => {


              if (message.role === Role.ASSISTANT) {
                const streaming = chat.streamingResponse && index === chat.messages.length - 1 ? true : false;
                return <AssistantMessage
                  key={index}
                  message={message as any}
                  streamRunning={streaming}
                  onShowSources={showSources}
                />;
              }
              else {
                return <UserMessages key={index} message={message as any} />;
              }
            })
          }
        </VStack>
        <div ref={ref} />
      </VStack>
      <MessageSources
        isOpen={Boolean(currMessageSources)}
        onClose={hideSources}
        message={chat?.messages.find(m => m.runId && m.runId === currMessageSources) as any}
      />
    </>
  );
});



export default ChatMessages;


const UserMessages: React.FC<{ message: Message }> = ({ message }) => {
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <>
      <MessageFiles message={message} />
      <HStack
        justify="end"
        bgColor={bgColor}
        borderRadius="20px"
        alignSelf="end"
        px={4}
        py={2}
      >
        <Box maxWidth={600}>
          <MessageContent message={message} />
        </Box>
      </HStack>
    </>
  );
}

interface AssistantMessageProps {
  message: Message;
  streamRunning: boolean;
  onShowSources: (runId: string) => void;
}

const getMessageCopyText = (message: Message) => {
  return message.content.map(c => c.content.value).join('\n');
}

const AssistantMessage: React.FC<AssistantMessageProps> = ({ message, streamRunning, onShowSources }) => {
  const { onCopy } = useClipboard(getMessageCopyText(message));
  const toast = useToast();
  const chatsState = useChats();


  const handleOnCopy = () => {
    onCopy();
    toast({
      title: 'Copied to clipboard.',
      description: 'The message has been copied to your clipboard.',
      status: 'success',
      duration: 1000,
      isClosable: true,
      position: 'top',
    });
  }

  const run = chatsState.runs[ message.runId ];

  return (
    <HStack justify="start" alignSelf="start" maxWidth={600} width="100%" align="start">
      <Avatar name='System Message' src='/logo512.png' size="sm" />
      <VStack align="start">
        <MessageContent message={message} />

        <HStack justify="start" spacing={3} visibility={streamRunning ? 'hidden' : 'visible'}>
          <Button onClick={handleOnCopy} size="xs" variant="ghost" leftIcon={<Icon as={MdContentCopy} fontSize="13px" />}>
            Copy
          </Button>
          <Button
            size="xs"
            variant="ghost"
            leftIcon={<Icon as={MdLanguage}
              fontSize="13px" />}
            onClick={() => {
              onShowSources(message.runId);
            }}
          >
            Sources {
              run && (
                (`(${run.contextDocuments.length})`)
              )
            }
          </Button>
        </HStack>
      </VStack>
    </HStack>
  );
}


interface MessageSourcesProps {
  message?: Message;
  isOpen: boolean;
  onClose: () => void;
}
const MessageSources: React.FC<MessageSourcesProps> = ({ message, isOpen, onClose }) => {
  const chats = useChats();

  useEffect(() => {
    if (isOpen && message) {
      chats.loadRunById(message.runId);
    }

    //eslint-disable-next-line
  }, [ isOpen ]);

  const isLoading = message ? chats.runLoading[ message.runId ] : false;
  const run = message ? chats.runs[ message.runId ] : null;

  const iconBgColor = useColorModeValue("gray.100", "gray.800");


  const documents = run?.contextDocuments || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sources</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack pb={4}>
            {
              isLoading && (
                <>
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                  <Skeleton width="100%" height="10px" />
                </>
              )
            }
            {
              !isLoading && run?.contextDocuments.length === 0 && (
                <Text>No sources for this message.</Text>
              )
            }
            {
              !isLoading && documents.map((doc, index) => (
                <HStack width="100%" key={index}>
                  <Flex
                    px={3}
                    py={2}
                    borderRadius={5}
                    bgColor={iconBgColor}
                    height="100%"
                  >
                    <Icon
                      color="gray.500"
                      as={MdLanguage}
                    />
                  </Flex>
                  <Link
                    href={doc.url}
                    isExternal
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {doc.filename} {doc.trailer ? `(${doc.trailer})` : ''}
                  </Link>
                  <ExternalLinkIcon mx="2px" mb="3px" />
                </HStack>
              ))
            }
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}



const MessageContent: React.FC<{ message: Message }> = ({ message }) => {

  const children: ReactNode[] = [];

  for (let i = 0; i < message.content.length; i++) {
    const content = message.content[ i ];

    if (content.content.case !== 'text') {
      continue;
    }

    if (content.content.case === 'text') {
      if (message.role === Role.ASSISTANT) {
        children.push(
          <MessageText key={i} text={content.content.value} />
        );
      }
      else {
        children.push(
          <VStack key={i} spacing={0} align="start">
            {
              content.content.value.split('\n').map((text, index) => (
                <Text key={index}>{text}</Text>
              ))
            }
          </VStack>
        );
      }
    }
    // else if (content.content.case === 'image') {
    //   if (content.content.value.source.case === 'url') {
    //     children.push(<Image key={i} src={content.content.value.source.value} width="300px" />);
    //   }
    //   else if (content.content.value.source.case === 'data') {
    //     const decoder = new TextDecoder('utf8');
    //     const b64encoded = btoa(decoder.decode(content.content.value.source.value.data));
    //     children.push(<Image key={i} src={b64encoded} width="300px" />);
    //   }
    // }
    // else {
    //   children.push(
    //     <Text key={`${i}-1`}>Unsupoported message type: {content.content.case}</Text>
    //   );
    // }

  }

  return (
    <VStack align="start">
      {children}
    </VStack>
  );
}

const MessageFiles: React.FC<{ message: Message }> = ({ message }) => {
  
  var chats = useChats();
  useEffect(() => {
    for (let i = 0; i < message.content.length; i++) {
      const content = message.content[ i ];

      if (content.content.case !== 'file') {
        continue;
      }

      chats.loadFile(content.content.value.source.value!);
    }
  //eslint-disable-next-line
  }, [ ]);
  const children: ReactNode[] = [];

  for (let i = 0; i < message.content.length; i++) {
    const content = message.content[ i ];

    if (content.content.case !== 'file') {
      continue;
    }

    console.log(content.content.value.source.value);
  }

  if (children.length === 0) {
    return null;
  }

  return (
    <Wrap>
      {children}
    </Wrap>
  );
}


const MessageText: React.FC<{ text: string }> = ({ text }) => {
  const boxBgColor = useColorModeValue('gray.100', 'gray.700');
  return (
    <Box
      overflow="auto"
      maxWidth={900}
      width="100%"
    >
      <ReactMarkdown
        components={{
          code({ node, className, children, ...props }) {
            const isBlockCode = className !== undefined;
            return isBlockCode ? (
              // Code block
              <Box
                as="pre"
                bg={boxBgColor}
                p={2}
                borderRadius="md"
                overflow="auto"
                maxWidth={900}
                width="100%"
              >
                <code className={className} {...props}>
                  {children}
                </code>
              </Box>
            ) : (
              // Inline code
              <Box
                as="code"
                bg={boxBgColor}
                p="0.2em"
                borderRadius="md"
                maxWidth={900}
                width="100%"
                {...props}
              >
                {children}
              </Box>
            );
          },
        }}
      >
        {text}
      </ReactMarkdown>
    </Box>
  );
}