import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import AccountWidget from "./AccountWidget";
import { useChats } from "../../state/chats";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdDelete, MdEdit, MdMoreHoriz } from "react-icons/md";
import { useDialog } from "../DialogProvider";



const LeftPanel: React.FC<{ isInDrawer: boolean }> = ({ isInDrawer }) => {
  const chatsState = useChats();
  const observer = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const [ renameDialogOpen, setRenameDialogOpen ] = useState(false);
  const [ chatName, setChatName ] = useState('');
  const renameInitialRef = React.useRef(null)

  useEffect(() => {
    chatsState.loadChats();
    // eslint-disable-next-line
  }, []);



  useEffect(() => {
    console.log('setup intersection observer');
    if (sentinelRef.current) {
      observer.current = new IntersectionObserver(
        ([ entry ]) => {
          if (entry.isIntersecting) {
            chatsState.loadChats();
          }
        },
        {
          root: document.querySelector('#chat-list'),
          rootMargin: '0px',
          threshold: 1.0,
        }
      );

      observer.current.observe(sentinelRef.current);

      return () => {
        console.log('remove');
        if (observer.current) {
          observer.current.disconnect(); // Clean up the observer
        }
      };
    }

    //eslint-disable-next-line
  }, []);

  const params = useParams();
  const dialog = useDialog();

  const onDeleteChat = (threadId: string) => {
    dialog.confirm({
      title: 'Delete Chat',
      subtitle: 'Are you sure you want to delete this chat?',
      onConfirm: () => {
        navigate('/', { replace: true });
        chatsState.deleteChat(threadId);
      }
    });
  }

  const onEditChat = (threadId: string) => {
    const thread = chatsState.threads.find((t) => t.id === threadId);
    setChatName(thread?.description || '');
    setRenameDialogOpen(true);
  }

  const closeRenameDialog = () => {
    setRenameDialogOpen(false);
  }

  const renameChat = () => {
    setRenameDialogOpen(false);
    if (params.threadId) {
      chatsState.renameChat(params.threadId, chatName);
    }
  }

  return (
    <>
      <Flex
        direction="column"
        width={{
          base: "100%",
          lg: "250px",
        }}
        pr={{
          base: 0,
          lg: 0,
        }}
        height={"100%"}
        display={{
          base: isInDrawer ? 'flex' : 'none',
          lg: 'flex',
        }}
      >
        <VStack width="100%" align="start" justify="stretch" px={3} pt={4} pb={2}>
          <Image src="/logo512.png" width="70px" />
          <Button
            size="md"
            colorScheme="primary"
            color="black"
            variant="solid"
            mt={5}
            isDisabled={false}
            isLoading={false}
            height="40px"
            lineHeight="40px"
            as={Link}
            to={`/`}
            width="100%"
          >
            New Chat
          </Button>
        </VStack>
        <VStack spacing={1} align="stretch" height="100%" overflow="scroll" mt={0}>
          {chatsState.loadingList &&
            (
              <>
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
                <Skeleton height='25px' />
              </>
            )
          }
          <>
            {
              !chatsState.loadingList && chatsState.threads.map((thread, index) => (
                <ChatButton
                  key={index}
                  threadId={thread.id}
                  description={thread.description}
                  isSelected={thread.id === params.threadId}
                  inDrawer={isInDrawer}
                  onDelete={onDeleteChat}
                  onEdit={onEditChat}
                />
              ))
            }

            <Flex ref={sentinelRef} height="20px" width="100%" flexShrink={0} align="center" justify="center" pt={6} pb={10}>
              {
                chatsState.loadingList && (
                  <Spinner />
                )
              }
            </Flex>


          </>

        </VStack>


        <AccountWidget isInDrawer={isInDrawer} />
      </Flex>
      <Modal
        isOpen={renameDialogOpen}
        onClose={closeRenameDialog}
        isCentered
        initialFocusRef={renameInitialRef}

      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Chat</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Chat name</FormLabel>
              <Input ref={renameInitialRef} value={chatName} onChange={(e) => setChatName(e.target.value)} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={renameChat} isDisabled={!chatName}>
              Save
            </Button>
            <Button onClick={closeRenameDialog}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LeftPanel;



interface ChatButtonProps {
  threadId: string;
  description: string;
  isSelected: boolean;
  inDrawer: boolean;
  onDelete: (threadId: string) => void;
  onEdit: (threadId: string) => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ threadId, description, isSelected, inDrawer, onEdit, onDelete }) => {
  const overflowColor = useColorModeValue('gray.50', 'gray.900');
  const overflowColorInDrawer = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box position="relative" role="group">
      <Button
        variant={isSelected ? "solid" : "ghost"}
        justifyContent="start"
        isTruncated
        display="block"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="clip"
        textAlign="left"
        flexShrink={0}
        as={Link}
        to={`/chat/${threadId}`}
        height="40px"
        lineHeight="40px"
        borderRadius={0}
      >{description}</Button>
      <Box
        position="absolute"
        top={0}
        right={0}
        width="60px"
        height="100%"
        pointerEvents="none"
        bgGradient={`linear(to-r, transparent, ${inDrawer ? overflowColorInDrawer : overflowColor})`}
      />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<MdMoreHoriz />}
          variant='ghost'
          size="sm"
          position="absolute"
          right={1}
          top="50%"
          transform="translateY(-50%)"
          borderRadius={90}
          display={isSelected ? 'flex' : 'none'}
          bgColor={useColorModeValue('gray.100', 'gray.700')}
          _hover={{
            bgColor: useColorModeValue('gray.200', 'gray.800')
          }}
          _active={{
            bgColor: useColorModeValue('gray.200', 'gray.800')
          }}
        />
        <MenuList>
          <MenuItem icon={<MdEdit />} onClick={() => {
            onEdit(threadId);
          }}>
            Rename
          </MenuItem>
          <MenuItem icon={<MdDelete />} onClick={() => {
            onDelete(threadId);
          }}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}