import { Box, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { RetrievalSettings } from "../../gen-ts/ai/assistants/v0/assistant_pb";
import CustomSlider from "../CustomSlider";



interface Props {
  retrievalSettings: RetrievalSettings;
  onUpdate: (data: RetrievalSettings) => void;
  isOpen: boolean;
  onClose: () => void;

}
const RetrievalSettingsDialog: React.FC<Props> = ({ isOpen, retrievalSettings, onUpdate, onClose }) => {
  const [ data, setData ] = useState(retrievalSettings);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{
        base: 'full',
        md: '4xl',
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Retrieval Settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box bgColor={useColorModeValue('gray.200', 'gray.800')} p={6} borderRadius={10} mb={5}>
            <VStack p={6} borderRadius={10} bgColor={useColorModeValue('white', 'gray.700')}>
              <CustomSlider
                disabled={false}
                label="Max Results"
                tooltip="Determines the number of top results the model retrieves from a dataset or a set of possibilities during operations like search or data retrieval. This setting controls the breadth of data considered in the model's responses, influencing both the diversity and specificity of the output. A higher Top K value may provide more varied results, while a lower value focuses on the most likely outcomes."
                defaultValue={data.topK!}
                min={1}
                max={200}
                step={1}
                onChange={(value) => {
                  data.topK = value;
                  setData(data.clone());
                  onUpdate(data.clone());
                }}
              />

              <Divider my={3} />

              <CustomSlider
                disabled={false}
                label="Minimum Relevance Score"
                tooltip="Minimum Relevance Score sets the threshold for filtering results based on their relevance to a query or task. This score helps in prioritizing higher quality and more pertinent responses by excluding those that do not meet the specified relevance criteria. Higher scores narrow down the results to the most relevant, whereas lower scores allow more results at the risk of including less pertinent ones."
                defaultValue={data.minRelevanceScore!}
                min={0}
                max={1}
                step={0.01}
                onChange={(value) => {
                  data.minRelevanceScore = value;
                  setData(data.clone());
                  onUpdate(data.clone());
                }}
              />
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RetrievalSettingsDialog;