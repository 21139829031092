import { createConnectTransport } from "@connectrpc/connect-web";
import { useUserState } from "./state/user"
import { config } from "./config";
import { createPromiseClient } from "@connectrpc/connect";
import { Assistants } from "./gen-ts/ai/assistants/v0/assistant_connect";
import { SlackConfig } from "./gen-ts/slack/slack_config_connect";
import { UserPreferences } from "./gen-ts/user/preferences/v0/preferences_connect";
import axios from "axios";
import { Stores } from "./gen-ts/ai/stores_connect";



export const getApiClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
  });
  const client = createPromiseClient(Assistants, transport);

  return {
    client,
    headers,
  }
}


export const getSlackApiClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
  });
  const client = createPromiseClient(SlackConfig, transport);

  return {
    client,
    headers,
  }
}

export const getUserPreferencesApiClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
  });
  const client = createPromiseClient(UserPreferences, transport);

  return {
    client,
    headers,
  }
}

export const getFileStoreClient = () => {
  var authToken = useUserState.getState().authToken;

  const headers = { Authorization: `Bearer ${authToken}` };
  const transport = createConnectTransport({
    baseUrl: config.grpcBackendUrl,
  });
  const client = createPromiseClient(Stores, transport);

  return {
    client,
    headers,
  }
}

export const getChatFilesApi = () => {
  return {
    client: axios.create({
      baseURL: config.grpcBackendUrl,
      headers: {
        "Content-type": "application/json",
      },
    }),
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${useUserState.getState().authToken}`,
    },
  }
}
