import React, { DragEvent, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Assistant } from "../../../gen-ts/ai/assistants/v0/assistant_pb";
import { AssistantFile } from "../../../types";
import { findFolderByPath, traverseFileTree } from "./traverseFileTree";
import { StringParam, useQueryParam } from "use-query-params";
import { getFileStoreClient } from "../../../api";
import { ListFilesRequest } from "../../../gen-ts/ai/stores_pb";

export const ROOT_FOLDER: AssistantFile = {
  type: 'folder',
  name: 'Root',
  path: '/',
  status: 'done',
  progress: 0,
  children: [],
}

interface DropZoneProps {
  onDrop?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragStart?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragEnd?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragLeave?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragOver?: (ev: DragEvent<HTMLDivElement>) => void,
}

interface DragItemProps {
  onDragStart?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragEnd?: (ev: DragEvent<HTMLDivElement>) => void,
  onDragLeave?: (ev: DragEvent<HTMLDivElement>) => void,
  draggable?: boolean;
}


interface IFileManagerContext {
  assistant: Assistant;
  rootFolder: AssistantFile;
  currentPath: string;
  currentFolder: AssistantFile | null;
  setCurrentPath: (path: string) => void,
  activeDropZone: string | null;
  activeDragItem: string | null;
  getDropZoneProps: () => DropZoneProps;
  getDragItemProps: () => DragItemProps;
  openFilePicker: () => void;
}

const FileManagerContext = React.createContext<IFileManagerContext>({
  rootFolder: ROOT_FOLDER,
  assistant: new Assistant(),
  currentPath: ROOT_FOLDER.path,
  currentFolder: ROOT_FOLDER,
  setCurrentPath: (path: string) => { },
  activeDropZone: null,
  activeDragItem: null,
  getDropZoneProps: () => ({}),
  getDragItemProps: () => ({}),
  openFilePicker: () => { },
});

interface Props {
  assistant: Assistant;
}



const FileManagerProvider: React.FC<PropsWithChildren<Props>> = ({ children, assistant }) => {
  const [ _currentPath, _setCurrentPath ] = useQueryParam('path', StringParam);
  const currentPath = _currentPath || ROOT_FOLDER.path;

  const [ activeDragItem, setActiveDragItem ] = useState<string | null>(null);
  const [ activeDropZone, setActiveDropZone ] = useState<string | null>(null);

  const dropZoneEnterTime = useRef<number | null>(null);

  const setCurrentPath = (path: string) => {
    _setCurrentPath(path, 'pushIn');
  }

  const [ rootFolder, setRootFolder ] = useState<AssistantFile>(ROOT_FOLDER);

  const currentFolder = useMemo(() => {
    return findFolderByPath(rootFolder, currentPath);
  }, [ rootFolder, currentPath ]);

  const ref = useRef<HTMLInputElement>(null);

  const onDrop = async (ev: DragEvent<HTMLDivElement>) => {
    console.log("dropped on", (ev.target as HTMLDivElement).id);
    ev.preventDefault();

    setActiveDropZone(null);
    setActiveDragItem(null);

    if (!currentFolder) {
      console.error('Current folder not found');
      return;
    }

    if (ev.dataTransfer.files.length > 0) {
      const list: AssistantFile[] = [];

      const itemList = [ ...ev.dataTransfer?.items || [] ];
      const entryList: FileSystemEntry[] = [];

      // Convert DataTransferItem to FileSystemEntry synchronously
      for (let i = 0; i < itemList.length; i++) {
        const item = itemList[ i ];
        const entry = item.webkitGetAsEntry();
        if (entry) {
          entryList.push(entry);
        }
      }


      const targetPath = activeDropZone || currentFolder?.path || ROOT_FOLDER.path;
      for (let i = 0; i < entryList.length; i++) {
        const entry = entryList[ i ];
        if (entry) {
          const item = await traverseFileTree(entry, targetPath, targetPath);
          list.push(item);
        }
      }

      currentFolder!.children = [
        ...list,
        ...currentFolder!.children,
      ];
      setRootFolder({ ...rootFolder });
      if (targetPath === currentPath) {
        setCurrentPath(targetPath);
      }
    }
    else {
      const file = ev.dataTransfer.getData("assistant-file");
      console.log('file', file);
    }
  }

  useEffect(() => {
    fetchFiles();

    //eslint-disable-next-line
  }, []);

  const fetchFiles = async () => {
    const { client, headers } = getFileStoreClient();

    const request = new ListFilesRequest({
      storeId: assistant.storeId,
    })

    
    const resp = await client.listFiles(request, { headers });
    console.log(resp);

  }


  const onDragEnter = (ev: DragEvent<HTMLDivElement>) => {
    const dropZonePath = (ev.target as HTMLDivElement).getAttribute('data-path');
    const dropZoneIsFile = (ev.target as HTMLDivElement).getAttribute('data-file') === 'true';

    if (!dropZonePath) {
      console.warn('no drop zone path found', dropZonePath);
      return;
    }

    if (dropZoneIsFile) {
      console.log('drop zone is file', dropZonePath);
      dropZoneEnterTime.current = null;
      return;
    }

    dropZoneEnterTime.current = Date.now();

    setActiveDropZone(dropZonePath);

    ev.preventDefault();
  }

  const onDragEndContainer = (ev: DragEvent<HTMLDivElement>) => {
    setActiveDropZone(null);
    // ev.preventDefault();
  }

  const onItemDragStart = (ev: DragEvent<HTMLDivElement>) => {
    const itemPath = (ev.target as HTMLDivElement).getAttribute('data-path');
    const isFile = (ev.target as HTMLDivElement).getAttribute('data-file') === 'true';
    const isDir = (ev.target as HTMLDivElement).getAttribute('data-dir') === 'true';
    console.log('drag start', itemPath, ev.target);

    if (!itemPath) {
      return;
    }

    ev.dataTransfer.setData("dragging-item-path", itemPath);
    ev.dataTransfer.setData("is-dir", isDir ? 'true' : 'false');
    ev.dataTransfer.setData("is-file", isFile ? 'true' : 'false');
    setActiveDragItem(itemPath);
    console.log('set active drag item', itemPath);
  }

  const onItemDragEnd = (ev: DragEvent<HTMLDivElement>) => {
    dropZoneEnterTime.current = null;
    setActiveDragItem(null);
  }


  const openFilePicker = () => {
    if (ref.current) {
      ref.current.click();
    }
  }

  const onFileChoose = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const list: AssistantFile[] = [];
    for (const file of ev.target.files || []) {
      const assistantFile: AssistantFile = {
        type: 'file',
        name: file.name,
        path: ((currentFolder?.path || ROOT_FOLDER.path) + '/' + file.name).replace(/\/+/g, '/'),
        progress: 0,
        status: 'uploading',
        children: [],
      }

      list.push(assistantFile);
    }

    if (list.length > 0) {
      currentFolder!.children = [
        ...list,
        ...currentFolder!.children,
      ];
      setRootFolder({ ...rootFolder });
    }
  }

  const onDragOver = (ev: DragEvent<HTMLDivElement>) => {
    if (
      dropZoneEnterTime.current &&
      Date.now() - dropZoneEnterTime.current! > 500 &&
      activeDropZone &&
      currentFolder?.path !== activeDropZone
    ) {
      setCurrentPath(activeDropZone);
      dropZoneEnterTime.current = null;
    }
    ev.preventDefault();
  }



  const context: IFileManagerContext = {
    assistant,
    rootFolder: rootFolder,
    currentFolder: currentFolder,
    currentPath: currentPath || '',
    setCurrentPath,
    activeDragItem,
    activeDropZone,
    openFilePicker,
    getDropZoneProps: () => ({
      onDrop,
      onDragEnter,
      onDragEnd: onDragEndContainer,
      onDragLeave: onDragEndContainer,
      onDragOver,
    }),
    getDragItemProps: () => ({
      onDragStart: onItemDragStart,
      onDragEnd: onItemDragEnd,
      // onDragLeave: onItemDragEnd,
      draggable: true,
    }),
  };



  return (
    <FileManagerContext.Provider value={context}>
      {children}
      <input type="file" ref={ref} hidden onChange={onFileChoose} multiple />
    </FileManagerContext.Provider>
  );
};

export default FileManagerProvider;


export const useFileManager = () => {
  return useContext(FileManagerContext);
}